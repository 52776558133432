











































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  FundAppropriateApplyDto,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "FundAppropriateApplyDetail",
  components: {
    ExportWord,
    AuditBlock,
    AttachmentsView,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundAppropriateApplyDetail extends Vue {

  dataId?: number;
  detail: FundAppropriateApplyDto = {};
  typeList: any = [];
  styles = "";
  selector = "table-detail-world";
  filename = "一般业务走款申请详情";
  hostType = AttachmentHostType.FundAppropriateApply;


  contactType: any = [
    {
      text: "手机",
      value: 1,
    },
    {
      text: "邮箱",
      value: 2,
    },
    {
      text: "两种皆可",
      value: 3,
    },
  ];
  donationType: any = [
    {
      text: "物品捐赠",
      value: 1,
    },
    {
      text: "场地/其他捐赠",
      value: 2,
    },
  ];

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.$route.params.id) {
      this.fetchEnumType();
      this.fetchDetail();
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "NotifyTemplateType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }
  // 获取详情
  async fetchDetail() {
    api.fundAppropriateApply
      .get({ id: Number(this.$route.params.id) })
      .then((res) => {
        this.detail = { ...res };
      });
  }


  get auditApi(){
    return {canAudit: api.fundAppropriateApply.canAudit,userAudit: api.fundAppropriateApply.audit } as AuditApi
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
}
